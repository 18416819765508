<template>
    <AppContainer>
        <span slot="container">
            <div class="pr-2 pl-2">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/dashboard">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Configurações</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text :style="`height: ${$vuetify.breakpoint.height - 210}px; overflow-x: auto;`">
                        <v-expansion-panels class="pa-5" focusable>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Geral</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <FormInput
                                        label="Nome da loja"
                                        v-model="form.nome"
                                    />
                                    <FormInput
                                        label="Link da loja"
                                        v-model="form.link"
                                    />
                                    <div class="my-2 image-container">
                                        <span>Background</span>
                                        <ImageInput v-model="form.background" />
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Redes Sociais</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <FormInput
                                        label="E-Mail"
                                        v-model="form.redes_sociais.email"
                                    />
                                    <FormInputMask
                                        label="WhatsApp"
                                        prefix="+55"
                                        placeholder="(99) 99999-9999"
                                        :mask="['(##) ####-####', '(##) #####-####']"
                                        v-model="form.redes_sociais.whatsapp"
                                    />
                                    <FormInput
                                        label="Facebook"
                                        v-model="form.redes_sociais.facebook"
                                    />
                                    <FormInput
                                        label="Instagram"
                                        v-model="form.redes_sociais.instagram"
                                    />
                                    <FormInput
                                        label="Youtube"
                                        v-model="form.redes_sociais.youtube"
                                    />
                                    <FormInput
                                        label="Linkedin"
                                        v-model="form.redes_sociais.linkedin"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Cabeçalho</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <FormInput
                                        label="Slogan cabeçalho"
                                        v-model="form.slogan"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Contas PIX</v-expansion-panel-header>
                                <v-expansion-panel-content class="pb-4">
                                    <v-btn color="primary" class="my-4" @click="adicionarConta">
                                        <v-icon>mdi-plus</v-icon>&nbsp;Adicionar conta
                                    </v-btn>
                                    <v-row v-for="(conta, index) in contas" :key="index">
                                        <v-col>
                                            <FormInput
                                                label="Token Payments"
                                                v-model="conta.numero"
                                            />
                                        </v-col>
                                        <v-col>
                                            <FormInputMask
                                                label="Peso"
                                                placeholder="1 a 10"
                                                :mask="['##']"
                                                v-model="conta.peso"
                                            />
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn color="error" icon @click="removerConta(index)" class="mt-2">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Credenciais Mercado Pago</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <FormInput
                                        label="Public Key"
                                        v-model="form.mercado_pago.public_key"
                                    />
                                    <FormInput
                                        label="Access Token"
                                        v-model="form.mercado_pago.access_token"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Formas de Pagamento</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-switch
                                        v-model="form.formas_pagamento.cartao.ativo"
                                        label="Cartão de Crédito"
                                    />
                                    <v-switch
                                        v-model="form.formas_pagamento.pix.ativo"
                                        label="PIX"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Logo</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="my-2 image-container">
                                        <span>Logo</span>
                                        <ImageInput v-model="form.logo" />
                                        <div>
                                            <span>Tamanho recomendado: 280px X 140px</span>
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="warning" @click="$router.push('/dashboard')">Cancelar</v-btn>
                        <v-spacer />
                        <v-btn color="success" large @click="salvar" :loading="loading">
                            <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </span>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import FormInput from '@/components/FormInput';
import ImageInput from '@/components/ImageInput';
import FormInputMask from '@/components/FormInputMask';

export default {
    name: 'ConfigurationView',

    components: {
        AppContainer,
        ImageInput,
        FormInput,
        FormInputMask,
    },

    data: () => ({
        loading: false,
        form: {
            redes_sociais: {
                email: '',
                whatsapp: '',
                facebook: '',
                instagram: '',
                youtube: '',
                linkedin: '',
            },
            contas: [],
            mercado_pago: {
                public_key: '',
                access_token: '',
            },
            formas_pagamento: {
                cartao: {
                    ativo: false,
                },
                pix: {
                    ativo: false,
                },
            },
            slogan: 'A sua família merece a melhor assinatura de TV!',
            logo: [],
        },
        contas: [],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.$http.get('configuracoes/1').then(resp => {
                this.form = resp.data.data;
                this.contas = JSON.parse(JSON.stringify(this.form.contas));
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
            });
        },

        salvar() {
            this.loading = true;

            this.form.contas = JSON.parse(JSON.stringify(this.contas));

            this.$http.put('configuracoes/1', this.form).then(resp => {
                if (resp.data.type === 'warning') {
                    this.$toast.error(resp.data.msg);
                    return;
                }

                this.$toast.success('Atualizado com sucesso!');
            }).catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
            }).finally(() => (this.loading = false));
        },

        adicionarConta() {
            this.contas.push({});
        },

        removerConta(index) {
            this.contas.splice(index, 1);
        },
    },
}
</script>

<style scoped>
.image-container {
    border: solid 1px #888;
    border-radius: 5px;
}

.col {
    padding-bottom: 5px;
    padding-top: 5px;
}
</style>
